.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 60px 50px 60px 50px;
  width: 100%;
  text-align: center;
  align-items: center;
  background: #f1f2ff;
  justify-content: center;

  h2 {
    margin: 0;
    display: inline-block;
    text-align: center;
    color: rgb(56, 83, 154);
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
  }
}
