@import "../../scss/base.scss";

.footer {
  background: $color__very_light_gray;
  color: $color__amway_black;
  padding: 2.625rem 0.625rem;
  text-align: center;
  font-size: $font__size--medium;
  font-weight: $regular;
  position: relative;
  bottom: 0;
  width: 100%;

  &__divider {
    width: 0.0625rem;
    background: black;
    height: 1rem;
    display: flex;
    margin: 0rem 0.875rem;
  }

  &__footer-links {
    padding-bottom: 1.625rem;
    display: flex;
    justify-content: center;

    a {
      padding: 0.125rem 0rem;
      font-size: $font__size;
      font-weight: $bold;
      border-bottom: 0.125rem solid $color__amway_black;
      color: $color__amway_black;
      text-decoration: none;

      &:hover {
        color: $color__blue;
        border-color: $color__blue;
      }
    }

    span {
      align-items: center;
      display: flex;
    }
  }

  @include sm_l {
    font-size: $font__size--small;

    &__footer-links {
      a {
        font-size: $font__size--small;
      }
    }

    &__divider {
      height: 0.75rem;
      margin: 0rem 0.438rem;
    }
  }
}
