@import "../../scss/base.scss";

.header {
  display: flex;
  flex-direction: column;
  padding: 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #e4e4e4;

    &__left {
      flex: 1;
      font-size: 14px;
    }

    &__right {
      display: flex;

      &__language,
      &__help,
      &__user {
        display: flex;
        font-size: 14px;
        margin-right: 10px;
        border: 0;
        text-transform: capitalize;
        padding-right: 10px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 16px;

    &__left {
      display: flex;
      align-items: center;

      &__text {
        margin-right: 25px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
      }

      &__logo {
        margin-right: 50px;
      }
    }

    &__right {
      &__component {
        border: 1px solid #ddd;
        padding: 8px;
        border-radius: 5px;

        select {
          font-size: 16px;
          border: 0;
          font-weight: 600;
          text-transform: capitalize;
          padding: 0 10px;
        }
      }
    }
  }
}
