.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Set maximum width to 100% */
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1rem;
}

.table th {
  font-weight: 700;
}

.table th,
.table td {
  padding: 10px 20px;
  text-align: left;
}

.table tbody tr:nth-child(even) {
  background-color: #f4f4f4;
}
