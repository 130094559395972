// typography
$font__size: 1rem; /* 16px */
$font__size--big: 1.125rem; /* 18px */
$font__size--big_m: 1.375rem; /* 22px */
$font__size--huge: 1.75rem; /* 28px */
$font__size--large: 1.5rem; /* 24px */
$font__size--large_m: 1.625rem; /* 26px */
$font__size--large_l: 2rem; /* 32px */
$font__size--medium: 0.875rem; /* 14px */
$font__size--small: 0.75rem; /* 12px */
$font__fallback: sans-serif;
$font__amway-icons: "AmwayIcons";

$font-stack-source: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "Sans Serif";
$font__header: "GTWalsheimPro", $font__fallback;
$font__primary: "GTWalsheimPro", $font__fallback;
$font__primary--medium: "GTWalsheimProMedium", $font__fallback;
$font__primary--bold: "GTWalsheimProBold", $font__fallback;

// font weight
$thin: 100;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// breakpoints
$breakpoint__sm: 320px;
$breakpoint__sm_l: 480px;
$breakpoint__sm_xl: 562px;
$breakpoint__md_s: 620px;
$breakpoint__md_ms: 672px;
$breakpoint__md: 768px;
$breakpoint__md_m: 992px;
$breakpoint__md_l: 1024px;
$breakpoint__lg_s: 1080px;
$breakpoint__lg: 1280px;
$breakpoint__lg_l: 1440px;

// colors

/* Red */
$color__light_pink: #f6bcc7;
$color__red: #ed174c;
$color__error_red: #d91734;
$color__outline_red: #eb8593;
$color_light_red: #ffe2e2;
$color_light_red_bg: #fef3f4;
$color_dark_red: #7f3e3e;
$color__background_light_red: #fdf4f4;
$color__dark_red_11: #7f3e3e;
$color__light_red_12: #ffe2e2;
$color__bright_red: #ff0100;

/* Gray - White - Black */
$color__mine_shaft: #373e41;
$color__mine_shaft_70: #373e41;
$color__dark_grey: #56565a;
$color__light_background: #e5e5e5;
$color__white: #ffffff;
$color__black: #000000;
$color__amway_white: #f4f4f4;
$color__disabled: #d9d9d9;
$color__disabled_gray: #5e5e5e;
$color__amway_black: #2c2c2c;
$color__light_gray: #e4e4e4;
$color__medium_gray: #949494;
$color__very_light_gray: #fafafa;
$color__founders_platinum_gray: #57575b;
$color__dark_grey_on_white: #707070;
$color__light_gray_accent: #d6d6d6;
$color__light_gray_bg: #f0f0f0;
$color__error__red: #d91734;

/* Blue */
$color__blue: #38539a;
$color__dark_blue: #002f5f;
$color_founders_platinum_blue: #003061;
$color__light_blue: #e9f2fe;
$color__primary__blue: #008fc5;

$color__light_blue_6: #e5fdff;
$color__dark_blue_5: #396e75;

/* Purple */
$color__light_purple: #f1f2ff;
$color_purple: #94a3cd;
$color__dark_purple: #1e247f;
$color__background_purple: #1e247f1a;
$color__link_purple: #551a8b;

/* Orange */
$color__sandy_brown: #f89d6a;
$color__kournikova: #f8c86a;
$color__dark_orange: #a65523;
$color__darker_orange: #8f4700;
$color__light_orange: #fff1e8;
$color_orange: #a66400;
$color__extra_light_orange: #fff9f0;
$color__data_viz_orange: #da7600;
$color__outline_orange: #d1b278;
$color__light_orange_10: #fff1e8;
$color__dark_orange_9: #a65523;

/* Yellow */
$color__dark_yellow_13: #62625a;
$color__light_yellow_14: #f8f8e7;

/* Green */
$color__light_green: #107f47;
$color__light_green_8: #f0fae5;
$color__light_green_bg: $color__light_green_8;
$color__dark_green: #546223;
$color__solitude: #f0f8f2;
$color__green_outline: #7fbc96;

$banner_theme_backgrounds: (
  "green": $color__light_green_bg,
  "red": $color_light_red_bg,
  "yellow": $color__extra_light_orange,
  "blue": $color__light_purple,
);

$banner_theme_foregrounds: (
  "green": $color__light_green,
  "red": $color__red,
  "yellow": $color_orange,
  "blue": $color__blue,
);
