@import "../../scss/base.scss";

.input {
  &__label {
    color: #303030;
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.select__control {
  border: 2px solid #000 !important;
  border-radius: 8px !important;
}
