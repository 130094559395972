@import "./fonts.scss";
@import "./mixins.scss";
@import "./variables.scss";
@import "./grid.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: default;
  font-family: $font__primary !important;
}

#root {
  width: 100%;
}

html {
  font-size: 16px; // 1rem = 16px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

// acc-theme variables scaled to our base (1rem of 10px)
// see: https://github.com/AmwayACS/acc-neo/blob/main/packages/acc-wc-leaf/src/css/theme.example.css.js
:root {
  --acc-theme-font-heading1: 2.441rem;
  --acc-theme-font-heading2: 1.935rem;
  --acc-theme-font-heading3: 1.563rem;
  --acc-theme-font-heading4: 1.25rem;
  --acc-theme-font-heading5: 1rem;
  --acc-theme-font-heading6: 0.8rem;
  --acc-theme-font-small: 0.8rem;
  --acc-theme-front-sm-md: 0.875rem;
  --acc-theme-font-large: 1.5rem;
  --acc-theme-font-extra-large: 3rem;
  --acc-theme-font-scale: 100%;

  --acc-theme-layout-01: 1rem;
  --acc-theme-layout-02: 1.5rem;
  --acc-theme-layout-03: 2rem;
  --acc-theme-layout-04: 3rem;
  --acc-theme-layout-05: 4rem;
  --acc-theme-layout-06: 6rem;

  --acc-theme-spacing-01: 0.129rem;
  --acc-theme-spacing-02: 0.216rem;
  --acc-theme-spacing-03: 0.36rem;
  --acc-theme-spacing-04: 0.6rem;
  --acc-theme-spacing-05: 1rem;
  --acc-theme-spacing-06: 1.667rem;
  --acc-theme-spacing-07: 2.779rem;
  --acc-theme-spacing-08: 4.632rem;

  --font-heading6: var(--acc-theme-font-heading6, 0.8rem);

  // https://sass-lang.com/documentation/breaking-changes/css-vars
  --ar-title-font-family: #{$font__primary};
  --los-title-font-family: #{$font__primary};
  --los-title-margin-mobile: 0.75rem 0 0.75rem 1rem;
  --ar-title-margin-mobile: 0.75rem 0 0.75rem 1rem;
}

a:link,
a:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

body {
  height: 100vh;
  min-width: 23.125rem;
  font-size: 1rem;
  color: $color__amway_black;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

@keyframes color-shift {
  0% {
    color: transparent;
    background: #bbbbbb;
  }
  50% {
    color: transparent;
    background: #eee;
  }
  100% {
    color: transparent;
    background: #bbbbbb;
  }
}

.loading {
  animation: color-shift 1s infinite;
  height: 10rem;
  margin: 1rem;
  border-radius: 0.5rem;
}
