.content {
  display: grid;
  flex: 1;

  &__with-sidebar {
    grid-template-columns: 20% 80%;
  }
}

.container {
  padding: 25px 50px;
  display: block;
}

.row {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.column-three {
  flex-basis: calc(33.33% - 10px); /* Adjust width for 3 columns with spacing */
  box-sizing: border-box;
  padding-right: 10px;
}

.column-one {
  flex-basis: 100%; /* 100% width for a single column */
  box-sizing: border-box;
}

.column-two {
  flex-basis: calc(50% - 10px); /* Adjust width for 2 columns with spacing */
  box-sizing: border-box;
}

.column-four {
  flex-basis: calc(25% - 10px); /* Adjust width for 4 columns with spacing */
  box-sizing: border-box;
}

.spacing-one {
  padding-right: 1rem;
}

.spacing-half-one {
  padding-right: 0.5rem;
}

.right {
  text-align: right;
}

/* Adjust the width of the container */
.w-3 {
  width: 30rem;
}

/* Adjust to add additional height into the container */
.h-1 {
  padding-bottom: 1rem;
}
.h-2 {
  padding-bottom: 2rem;
}
.h-3 {
  padding-bottom: 3rem;
}
.h-4 {
  padding-bottom: 4rem;
}
.h-5 {
  padding-bottom: 5rem;
}
.h-6 {
  padding-bottom: 6rem;
}
