/* Button.scss */
.button {
  display: inline;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.8rem;
  padding: 6px 32px;
  font-size: 1rem;

  &:hover {
    background-color: #2980b9;
    color: #fff;
  }

  &.disabled {
    background-color: #fff;
    color: #5d5d5d;
    border-color: #dcdcdc;
    cursor: not-allowed;

    &:hover {
      background-color: #dcdcdc !important;
      color: #5d5d5d !important;
      border-color: #dcdcdc !important;
      cursor: not-allowed;
    }
  }

  &.with-border {
    border: 2px solid; /* No color specified here */
  }

  &:active {
    background-color: #21618c;
  }

  &.black {
    color: #000;
    background: #fff;
    border-color: #000;

    &:hover {
      background: #000;
      color: #fff;
      border-color: #000;
    }
  }

  &.primary-black {
    color: #fff;
    background: #2c2c2c;
    border-color: #2c2c2c;

    &:hover {
      background: #000000;
      color: #fff;
      border-color: #000000;
    }
  }

  &.primary-purple {
    color: #ffffff;
    background: #39549a;
    border-color: #39549a;

    &:hover {
      color: #ffffff;
      background: #1c3067;
      border-color: #1c3067;
    }
  }

  &.primary-white {
    background: #fff;
    color: #38539a;
    border-color: #fff;

    &:hover {
      background: #e4e4e4;
      color: #38539a;
      border-color: #e4e4e4;
    }
  }

  &.purple {
    color: #39529c;
    background: #fff;
    border-color: #39529c;

    &:hover {
      @extend .purple-style-hover; // Extend the hover style
    }
  }

  &.secondary-black {
    color: #2e2e2e;
    background: #fff;
    border-color: #2e2e2e;

    &:hover {
      background: #fff;
      color: #375299;
      border-color: #375299;
    }
  }

  &.secondary-white {
    color: #fff;
    background: none;
    border-color: #fff;

    &:hover {
      background: #e3e3e3;
      color: none;
      border-color: #e3e3e3;
    }
  }

  &.small {
    font-size: 0.825rem;
    font-weight: 600;
  }

  &.normal {
    font-size: 1rem;
    font-weight: 600;
  }

  &.large {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .icon-container {
    display: inline-block;
    height: 100%; /* Ensure the icon container takes up the full height of the button */
  }

  .icon-left {
    padding-right: 10px;
  }

  .icon-right {
    padding-left: 10px;
  }
}

.purple-style-hover {
  background: #39529c;
  color: #fff;
  border-color: #39529c;
}

.icon {
  height: 100%; /* Ensure the icon takes up the full height of the icon container */
}
