@import "./../../../common/scss/base.scss";

.container {
  min-height: 90%;
  overflow: hidden;
}

.login-container {
  padding: 0.5rem;
  width: 354px;
  height: 255px;
  font-weight: $regular;
  float: none;
  margin: 0 auto;
}

.login-button {
  border-width: 0px;
  width: 280px;
  height: 44px;
  background: inherit;
  background-color: $color__dark_blue !important;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: $color__dark_blue !important;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: $font-stack-source !important;
  font-weight: $bold !important;
  font-style: normal;
  font-size: $font__size !important;
  color: $color__white !important;
  text-align: center;
  cursor: pointer;
}

.login-text {
  width: 100%;
  font-family: $font-stack-source;
  font-weight: $regular;
  font-style: normal;
  font-size: $font__size--big;
  color: $color__dark_grey;
  margin: 10px 0;
}

.p {
  padding: 72px 24px 24px 0px;
}

@media (max-width: 480px) {
  .login-container {
    padding: 0px 26px;
  }
}
