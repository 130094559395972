.title {
  /* Common styles for all sizes */
  font-weight: 600;

  &.title-small {
    /* Styles for small size */
    font-size: 1rem;
  }

  &.title-medium {
    /* Styles for medium size (default) */
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 900;
  }

  &.title-large {
    /* Styles for large size */
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 900;
  }
}
