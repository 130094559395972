.section {
  border-bottom: 1px solid rgb(213, 214, 214);
  padding-bottom: 10px;
  margin-bottom: 10px;

  h2 {
    font-size: 20px;
    font-weight: 600;
    display: flex;
  }
}
