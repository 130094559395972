@import "../../scss/base.scss";

.textarea {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    color: #303030;
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__form {
    border: 2px solid #2f2f2f;
    border-radius: 0.5rem;
    padding: 8px 12px;
    color: #6c6c6c;
    font-size: 1rem; /* Adjust the font size as needed */

    &:hover,
    &:focus {
      color: #000;
    }

    &::placeholder {
      color: #6c6c6c;
    }

    &:read-only {
      background-color: #f4f4f4; /* Add a different background color for read-only */
      border-color: #ddd; /* Adjust border color for read-only */
      color: #888; /* Adjust text color for read-only */
      cursor: not-allowed; /* Show a not-allowed cursor for read-only */
    }
  }
}
