@import "../../scss/base.scss";

$primaryFontColor: #000;
$secondaryFontColor: #888;
$hoverBackgroundColor: #f8f8f8;
$marginLeftSecondaryList: 0px;

.sidebar {
  padding: 25px;
  font-size: 0.825rem;
  border-right: 1px solid #ddd;
  text-transform: capitalize;
}

.sidebar-navigation {
  nav {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        color: $primaryFontColor;
        padding: 8px;
        cursor: pointer;

        ul {
          list-style-type: none;
          margin-top: 8px;
          padding: 0;
          margin: 0;
          margin-left: $marginLeftSecondaryList;

          li {
            color: $secondaryFontColor;
            padding: 8px;
            cursor: pointer;
            padding-left: 25px;

            &:hover {
              background-color: $hoverBackgroundColor;
            }
          }
        }
      }
    }
  }
}
