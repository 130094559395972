@import "./variables";

@mixin less_than_tablet {
  @media (max-width: ($breakpoint__md - 1)) {
    @content;
  }
}

@mixin less_than_desktop_inclusive {
  @media (max-width: ($breakpoint__md_l - 1)) {
    @content;
  }
}

@mixin from_tablet {
  @media (min-width: $breakpoint__md) {
    @content;
  }
}

@mixin from_tablet_to_desktop_inclusive {
  @media (min-width: $breakpoint__md) and (max-width: ($breakpoint__md_l - 1)) {
    @content;
  }
}

@mixin from_mobile_to_tablet_inclusive {
  @media (min-width: $breakpoint__md_s) and (max-width: ($breakpoint__md_l - 1)) {
    @content;
  }
}

@mixin tablet_only {
  @media (min-width: $breakpoint__md_ms) and (max-width: ($breakpoint__md_l - 1)) {
    @content;
  }
}

@mixin over_md_ms {
  @media (min-width: $breakpoint__md_ms) {
    @content;
  }
}

@mixin under_md_ms {
  @media (max-width: $breakpoint__md_ms) {
    @content;
  }
}

@mixin over_lg_exclusive {
  @media (min-width: ($breakpoint__lg_l + 1)) {
    @content;
  }
}

@mixin over_lg {
  @media (min-width: $breakpoint__lg_l) {
    @content;
  }
}

@mixin over_md_m {
  @media (min-width: $breakpoint__md_m) {
    @content;
  }
}

@mixin lg_l {
  @media (max-width: $breakpoint__lg_l) {
    @content;
  }
}

@mixin over__md_l {
  @media (min-width: $breakpoint__md_l) {
    @content;
  }
}

@mixin over_lg_s {
  @media (min-width: $breakpoint__lg_s) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $breakpoint__lg) {
    @content;
  }
}

@mixin lg_s {
  @media (max-width: $breakpoint__lg_s) {
    @content;
  }
}

@mixin md_l {
  @media (max-width: ($breakpoint__md_l - 1)) {
    @content;
  }
}

@mixin md_m {
  @media (max-width: $breakpoint__md_m) {
    @content;
  }
}

@mixin md {
  @media (max-width: $breakpoint__md) {
    @content;
  }
}

@mixin md_landscape {
  @media screen and (max-width: $breakpoint__md) and (orientation: landscape) {
    @content;
  }
}

@mixin md_s {
  @media (max-width: $breakpoint__md_s) {
    @content;
  }
}

@mixin larger_than_mobile {
  @media (min-width: ($breakpoint__sm_xl + 1)) {
    @content;
  }
}

@mixin sm_xl {
  @media (max-width: $breakpoint__sm_xl) {
    @content;
  }
}

@mixin sm_l {
  @media (max-width: $breakpoint__sm_l) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $breakpoint__sm) {
    @content;
  }
}

@mixin banner_theme($color) {
  background: map-get($map: $banner_theme_backgrounds, $key: $color);
  color: map-get($map: $banner_theme_foregrounds, $key: $color);
}
