@import "../../common/scss/base.scss";

.error_container {
  width: 100%;
  height: 100%;
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.align_center {
    padding: 66px 0;
  }
  .error_message_section {
    .error_message_1 {
      text-align: center;
      font-size: 49px;
      line-height: 56px;
    }
    .error_message_2 {
      text-align: center;
      font-size: 18px;
      line-height: 21px;
      margin-top: 10px;
    }
  }
  .error_msg {
    margin-top: 15px;
    font-size: medium;
    color: red;
  }
}
